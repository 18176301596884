import api from '../utilPartner';

const controller = 'ImportacaoPessoa';
const urlPessoa = process.env.VUE_APP_PERSON_API + controller;
const urlPartner = process.env.VUE_APP_ROOT_API + controller;

export default {
  controller,
  Localizar: codigo => {
    return api.Axios.GetParams(`${urlPessoa}/${codigo}`);
  },
  Listar: dto => {
    return api.Axios.GetParams(urlPessoa, dto);
  },
  ImportacaoPessoaResumo: dto => {
    return api.Axios.GetParams(`${urlPessoa}/ImportacaoPessoaResumo`, dto);
  },
  Importar: dto => {
    return api.Axios.PostForm(`${urlPessoa}/Importar`, dto);
  },
  ResumoArquivo: dto => {
    return api.Axios.PostForm(`${urlPessoa}/Arquivo/Resumo`, dto);
  },
  Reprocessar: (dto, codigo) => {
    return api.Axios.Put(urlPessoa, codigo, dto);
  },
  Criar: (dto) => {
    return api.Axios.Post(urlPessoa, dto);
  },
  CriarSync: (dto) => {
    return api.Axios.Post(`${urlPessoa}/Sync`, dto);
  },
  Deletar: codigo => {
    return api.Axios.Delete(urlPessoa, codigo);
  },
  DeletarTodos: () => {
    return api.Axios.Delete(`${urlPessoa}/Todos`, '');
  },
  Download: (modelo) => {
    const csvPath = process.env.VUE_APP_PERSON_API + 'Static/' + modelo;

    return api.Axios.GetParams(csvPath);
  },
  ListarHistoricoPorCliente: dto => {
    return api.Axios.GetParams(urlPartner + '/ListarHistoricoPorCliente', dto);
  },
  ListarHistorico: dto => {
    return api.Axios.GetParams(urlPartner + '/ListarHistorico', dto);
  },
};
